<template>
  <footer class="bg-[#232323]">
    <div class="mx-auto w-full max-w-screen-xl">
      <div class="px-4 py-3 bg-[#232323] md:flex md:items-center md:justify-between">
        <span class="flex text-sm text-gray-500 dark:text-gray-300 justify-center">
            <img width="60px" class="rounded-full " :src="logo">
        </span>
        <div class="flex mt-4 justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
          <a :href="tapLinkUrl" class="flex items-center text-gray-400 hover:text-green-900 dark:hover:text-white">
            <span>TapLink</span>
            <img width="35px" class="rounded-full " :src="tapLink">
          </a>
          <a :href="instagramUrl" class="text-gray-400 hover:text-gray-900 dark:hover:text-white">
            <img width="35px" class="rounded-full " :src="instagram">
          </a>
          <a :href="telegramUrl" class="text-gray-400 hover:text-gray-900 dark:hover:text-white">
            <img width="35px" class="rounded-full " :src="telegram">
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

import {onMounted} from "vue";
import logo from '@/assets/images/calculate-logo-black.png';
import instagram from '@/assets/images/icons/instagram.svg';
import telegram from '@/assets/images/icons/telegram.svg';
import tapLink from '@/assets/images/icons/taplink.png';

export default {
  name: 'DataCalculator',

  setup() {
    const tapLinkUrl = 'https://taplink.cc/chinesemetaphysicsarmenia';
    const telegramUrl = 'https://t.me/chinesemetaphysicsarmenia';
    const instagramUrl = 'https://www.instagram.com/chinese_metaphysics_armenia';

    onMounted(() => {

    });

    return {
      logo,
      instagram,
      telegram,
      tapLink,
      tapLinkUrl,
      telegramUrl,
      instagramUrl,
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
