import { createRouter, createWebHistory } from 'vue-router';
import DataCalculator from '@/components/DateCalculator.vue';

const routes = [
    {
        path: '/',
        redirect: '/calculate-matrix',
    },
    {
        path: '/calculate-matrix',
        name: 'DataCalculator',
        component: DataCalculator,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
